import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Breadcrumbs } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breadcrumbs"
    }}>{`Breadcrumbs`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Breadcrumbs from 'mfcl/Breadcrumbs'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Breadcrumbs} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Breadcrumbs>\n  <a href=\"/components-breadcrumbs\">Order Tracking</a>\n  <a href=\"/components-breadcrumbs\">Track My Order</a>\n  <a href=\"/components-breadcrumbs\">Order Info</a>\n</Breadcrumbs>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Breadcrumbs,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Breadcrumbs mdxType="Breadcrumbs">
    <a href="/components-breadcrumbs">Order Tracking</a>
    <a href="/components-breadcrumbs">Track My Order</a>
    <a href="/components-breadcrumbs">Order Info</a>
  </Breadcrumbs>
    </Playground>
    <h2 {...{
      "id": "separator"
    }}>{`Separator`}</h2>
    <Playground __position={2} __code={'<Breadcrumbs separator=\">\">\n  <a href=\"/components-breadcrumbs\">Page 1</a>\n  <a href=\"/components-breadcrumbs\">Page 2</a>\n  <a href=\"/components-breadcrumbs\">Page 3</a>\n</Breadcrumbs>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Breadcrumbs,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Breadcrumbs separator=">" mdxType="Breadcrumbs">
    <a href="/components-breadcrumbs">Page 1</a>
    <a href="/components-breadcrumbs">Page 2</a>
    <a href="/components-breadcrumbs">Page 3</a>
  </Breadcrumbs>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      